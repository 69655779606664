import "./footer.css";

export default function Footer() {

    return(
        <>
        <div className="footer">
            <h4> &copy; 2023 | All Rights Reserved </h4>
            <h4> Developed By -  <a href="https://in.linkedin.com/in/ayush-dudhani-00b521200">Ayush</a></h4>
        </div>
        </>
    )
}